import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
		sentinalHandle,
		locationHandle,
		loadingHandle,
		supportHandle,
		bottomNavHandle,
		mobileMenuHandle,
		locationPopoutHandle,
		locationFormHandle,
		locationErrorHandle,
		geolocateHandle,
		menuHandle,
		locationOpenedClass,
		menuOpenedClass,
		fixedClass,
		lockedClass,
		emptyInstallerText,
		actions,
		events,
        state,
		isLocationLocked = false,
    }) {
        const el = document.getElementById(id);
		const sentinal = el.querySelector(sentinalHandle);
		const location = el.querySelector(locationHandle);
		const supportWrapper = el.querySelector(supportHandle);
		const bottomNav = el.querySelector(bottomNavHandle)
		const mobileMenu = el.querySelector(mobileMenuHandle)
		const loading = el.querySelector(loadingHandle);
		const locationForm = el.querySelector(locationFormHandle);

		const locationError = el.querySelector(locationErrorHandle);
		const geolocate = el.querySelector(geolocateHandle);
		const menus = el.querySelectorAll(menuHandle);

		let windowWidth = window.innerWidth

		function setMobileHeaderHeight() {
			mobileMenu.classList.remove('ready')

			mobileMenu.style.setProperty('--header-mobile-top-open', 'auto');
			mobileMenu.style.setProperty('--header-mobile-top-closed', 'auto');

			requestAnimationFrame(() => {
				const navRect = bottomNav.parentNode.getBoundingClientRect()
				const mobileNavRect = mobileMenu.getBoundingClientRect()

				const navHeight = navRect.top + navRect.height;
	
				mobileMenu.style.setProperty('--header-mobile-top-open', `${navHeight}px`);
				mobileMenu.style.setProperty('--header-mobile-top-closed', `-${Math.abs(mobileNavRect.height - navHeight)}px`);
	
				requestAnimationFrame(() => {
					mobileMenu.classList.add('ready')
				})
			})
		}

		function getInstallers({zip = '', lat = '', lng = ''}) {
			const { protocol, host } = window.location;
			const url = `${protocol}//${host}/shared/header`;
			const query = { zip, lat, lng };

			function cb(res) {
				const {
					location: loc,
					count,
					installersMarkup,
				} = JSON.parse(res);

				const markup = count === 0
					? `<p>${emptyInstallerText}</p>`
					: `<ul>${installersMarkup}</ul>`

                loading.style.display = 'none';
				locationForm.nextElementSibling.remove();
				locationForm.insertAdjacentHTML('afterend', markup);
				events.emit(actions.setActiveLocation, { activeLocation: loc });
			}

            loading.style.display = 'block';
			router.get({ url, query, cb });
		}

		function handleSetActiveLocation(e) {
			const { activeLocation } = e.detail;
			const { city, state: s } = activeLocation;

			location.querySelector('span').textContent = `${city}, ${s}`;
		}

		window.addEventListener('scroll', () => {
			if (sentinal.getBoundingClientRect().top <= 0) {
				el.classList.add(fixedClass)
			} else {
				el.classList.remove(fixedClass)
			}
		})

		function handleLocation() {
			el.classList.toggle(locationOpenedClass);
		}
		function handleLocationForm(e) {
			e.preventDefault();

			const formData = new FormData(locationForm);

			function cb(res) {
				const {
					success,
					errors,
					location: loc = null,
				} = JSON.parse(res);

				if (!success) {
					// Merge formData w/ errors from controller to update error
					// text for all inputs from form, sent to controller.
					const names = [
						...Array.from(formData.keys()),
						...Object.keys(errors),
					];

					names.forEach(name => {
						const error = errors[name] || '';

						events.emit(actions.setInputError, { name, error });
					});

					return;
				}

				if (loc) {
					getInstallers(loc)
				}
			}

			// Clear previous errors
			Array.from(formData.keys()).forEach(name => {
				events.emit(actions.setInputError, { name, error: '', });
			});
			router.post({ formData, cb });
		}
		function handleGeolocate() {
			function handleSuccess({ coords }) {
				const { latitude: lat, longitude: lng } = coords;

				getInstallers({ lat, lng });
			}

			function handleError() {
				locationError.textContent = 'Unable to retrieve your location';
			}

			if (!navigator.geolocation) {
				locationError.textContent = 'Geolocation is not supported by your browser';
			} else {
				navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
			}
		}
		function handleMenu() {
			el.classList.toggle(menuOpenedClass);
		}
		function handleLockLocation() {
			el.classList.add(lockedClass);
			location.removeEventListener('click', handleLocation);
			locationForm.removeEventListener('submit', handleLocationForm);
			geolocate.removeEventListener('click', handleGeolocate);
		}
        function setHeaderHeight() {
            state.headerHeight = el.offsetHeight;

			if (windowWidth !== window.innerWidth) {
				setMobileHeaderHeight()
			}

			windowWidth = window.innerWidth
        }

		function handleSupportOver() {
			supportWrapper.classList.add('is-active')
		}

		function handleSupportOut() {
			supportWrapper.classList.remove('is-active')
		}

		events.on(actions.setActiveLocation, handleSetActiveLocation);

		supportWrapper.addEventListener('mouseover', handleSupportOver)
		supportWrapper.addEventListener('mouseout', handleSupportOut)

		if (!isLocationLocked) {
			location.addEventListener('click', handleLocation);
			locationForm.addEventListener('submit', handleLocationForm);
			geolocate.addEventListener('click', handleGeolocate);
		}
		menus.forEach(m => {
			m.addEventListener('click', handleMenu);
		});
		events.on(actions.lockLocation, handleLockLocation);
        window.addEventListener('resize', setHeaderHeight);

        setHeaderHeight();

		setMobileHeaderHeight();

		document.body.addEventListener('click', (event) => {
			if (event.target.closest(locationPopoutHandle) || event.target.closest(locationHandle)) {
				return;
			}

			el.classList.remove(locationOpenedClass);
		})
    }
}
