import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const forms = el.querySelectorAll('form');

        function handleSubmit(e) {
            e.preventDefault();

            const formData = new FormData(e.currentTarget);

            function cb(res) {
                const { success } = JSON.parse(res);

                if (!success) {
                    // TODO: Handle error message
                    alert('Unable to select installer. Please try again later.'); // eslint-disable-line no-alert
                }

                events.emit(actions.updateCheckout);
            }

            router.post({ formData, cb });
        }

        forms.forEach(f => {
            f.addEventListener('submit', handleSubmit);
        });
    }
}
