export default class {
  constructor({
    id,
    pickerHandle,
    name,
    email,
    orderNumber,
    projectType,
    url,
    appointmentType,
    appointmentId
  }) {



    const el = document.getElementById(id);
    const picker = el.querySelector(pickerHandle);
    const script = document.createElement('script');
    let calendarElement = [...document.querySelectorAll('.js-form-input')];
    let listData = JSON.parse(localStorage.getItem('js-form-control'))
    let firstAvailable;
    let calendarPicker = document.querySelector('.calendar-picker')
    let freeReign = false;
    function isWeekend(string) {
      var date = new Date(string);
      return date.getDay() === 6 || date.getDay() === 0;
    }

    console.log('appointmentType: ', appointmentType);

    if (appointmentType === 'request') {

      firstAvailable = new Date(new Date().setDate(new Date().getDate() + 22))

      if (isWeekend(firstAvailable)) {
        let weekend = new Date(new Date().setDate(new Date().getDate() + 22));
        let setWeekend = weekend.setDate(weekend.getDate() + (1 + 7 - weekend.getDay()) % 7);
        firstAvailable = new Date(setWeekend)
      }
    }
    //get localStorage picker
    else if (listData) {
      // Parse the date into a Date object
      let date = new Date(listData.date);
      firstAvailable = date
      freeReign = true;
    }
    //confirmations and installations can be any time
    else {
      // Parse the date into a Date object
      let time = document.querySelector('.requested-time');
      let date = new Date(time.getAttribute('datetime'));
      // Add one day to the date
      date.setDate(date.getDate() + 1);
      firstAvailable = date
      freeReign = true;
    }

    function handleCalendarScriptLoad() {
      let myDate = new Date();
      myDate.setFullYear(2009);
      myDate.setMonth(7);
      myDate.setDate(25);

      let timeContainer = document.querySelector('.installation-window')
      let formElement = calendarPicker.querySelector('form');

      // let confirmationSlide = document.querySelector('.js-form__success-message')

      let submit = calendarPicker.querySelector('.submit');

      const csrfToken = formElement.dataset.csrfToken;

      const getAppointmentData = () => {
        let listData = JSON.parse(localStorage.getItem('js-form-control'))

        // Parse the date into a Date object
        let date = new Date(listData.date);

        // Define the start and end hours for the appointment
        let startHour;
        let endHour;
        if (listData.appointment === 'morning') {
          startHour = 8;
          endHour = 12;
        } else if (listData.appointment === 'afternoon') {
          startHour = 12;
          endHour = 16;
        }

        // Create new date objects for the start and end times
        let startDateTime = new Date(date.setHours(startHour, 0, 0, 0)).toISOString();
        let endDateTime = new Date(date.setHours(endHour, 0, 0, 0)).toISOString();

        // Get the users UTC offset in minutes
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return { startDateTime, endDateTime, timeZone };
      }

      const makeAppointmentRequest = (url, data) => {
        $.ajax({
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
          url: url,
          method: 'POST',
          data: data,
          success: function (response) {
            if (response.success) {
              window.location.reload();
            } else {
              alert("There was an error requesting the appointment");
              console.log(response);
            }
          },
          error: function (response) {
            alert("There was an error requesting the appointment");
            console.log(response);
          }
        })
      }

      const requestAppointment = () => {
        const { startDateTime, endDateTime, timeZone } = getAppointmentData();

        // Create the new data structure
        let data = {
          projectType: projectType,
          orderNumber: orderNumber,
          start: startDateTime,
          end: endDateTime,
          timeZone: timeZone,
          CRAFT_CSRF_TOKEN: csrfToken
        };

        makeAppointmentRequest('/cmd/appointments/request', data);
      }

      const confirmAppointment = () => {
        const { startDateTime, endDateTime } = getAppointmentData();

        // Create the new data structure
        let data = {
          appointmentIdentifier: appointmentId,
          confirmedStartTime: startDateTime,
          confirmedEndTime: endDateTime,
          CRAFT_CSRF_TOKEN: csrfToken
        };

        const urlParams = new URL(window.location.toLocaleString()).searchParams;
        const modalOpen = urlParams.get('modal');
        var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname
        if(modalOpen){
          window.history.pushState({}, document.title, newUrl);
        }

        makeAppointmentRequest('/cmd/appointments/confirm', data);


      }

      const installOrder = () => {
        const { startDateTime, endDateTime } = getAppointmentData();

        // Create the new data structure
        let data = {
          number: orderNumber,
          start: startDateTime,
          end: endDateTime,
          CRAFT_CSRF_TOKEN: csrfToken
        };

        const urlParams = new URL(window.location.toLocaleString()).searchParams;
        const modalOpen = urlParams.get('modal');
        var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname
        if(modalOpen){
          window.history.pushState({}, document.title, newUrl);
        }

        makeAppointmentRequest('/cmd/orders/install', data);
      }

      function handleSubmit(event) {
        console.log('handleSubmit');
        event.preventDefault();

        if (appointmentType === 'request') {
          requestAppointment();
        }
        else if (appointmentType === 'confirm') {
          confirmAppointment()
        }
        else if (appointmentType === 'install') {
          installOrder()
        }
        else {
          alert('missing appointment type.')
        }
      }


      submit.addEventListener('click', handleSubmit);

      if(appointmentType !== 'install'){
        let backButton = calendarPicker.querySelector('.back');
      backButton.addEventListener('click', function () {
        $('.calendar-picker__wrap').removeClass('hide')
        timeContainer.classList.remove('is-active')
      })
    }


      let values = JSON.parse(localStorage.getItem('js-form-control') || '{}');

      function selectDate(date) {
        $('.calendar-picker__picker').updateCalendarOptions({
          date: date
        });

        values['date'] = date;
        console.log('values:', values);

        localStorage.setItem('js-form-control', JSON.stringify(values));
        if (appointmentType != 'install') {
        $('.calendar-picker__wrap').addClass('hide')
        timeContainer.classList.add('is-active')
        }

      }


      if(appointmentType !== 'install'){
      let timeAppts = timeContainer.querySelectorAll('.time')
      timeAppts.forEach(appt => {
        appt.addEventListener('click', function () {
          values['appointment'] = appt.value;
          localStorage.setItem('js-form-control', JSON.stringify(values));
        })
      })
    }


      $('.calendar-picker__picker').calendar({// eslint-disable-line no-undef
        date: firstAvailable,
        weekDayLength: 3,
        prevButton: '<',
        nextButton: '>',
        monthYearOrder: 'my',
        monthYearSeparator: ' ',
        onClickDate: selectDate,
        onChangeMonth: function (date) { },
        onClickToday: function (date) { },
        onClickMonthNext: function (date) { },
        onClickMonthPrev: function (date) { },
        onClickYearNext: function (date) { },
        onClickYearPrev: function (date) { },
        onShowYearView: function (date) { },
        onSelectYear: function (date) { },
        showThreeMonthsInARow: true,
        enableMonthChange: true,
        enableYearView: true,
        showTodayButton: false,
        highlightSelectedWeekday: false,
        highlightSelectedWeek: false,
        showYearDropdown: false,
        min: null,
        max: null,
        disable: function (date) {
          if (freeReign) {
            return;
          }

          const numWeeks = 3;
          const now = new Date();
          let ifFirst;
          let withinThree = now.setDate(now.getDate() + numWeeks * 7);
          if (firstAvailable <= withinThree) {
            ifFirst = firstAvailable;
            console.log(ifFirst)
          } else {
            ifFirst = withinThree;
          }

          if (date.getDay() == 0 || date.getDay() == 6 || date < new Date() || date < ifFirst) {
            return date;
          }
        },
        startOnMonday: false,
      });

    }



    script.addEventListener('load', handleCalendarScriptLoad);
    // eslint-disable-line no-undef
    // Add Calendly class to the global namespace
    script.src = 'https://cdn.jsdelivr.net/gh/wrick17/calendar-plugin@master/calendar.min.js';
    document.head.appendChild(script);
  }
}
