// import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        project,
        checkoutRules,
        details,
        fromCheckoutValidation,
        actions,
        events,
        // refresh,
    }) {
        const el = document.getElementById(id);
        const checkoutButton = el.querySelector('.checkout-btn')

        let validationMessages = [];

        const validationCtas = {
            picInterior: {
                message: 'Before you proceed, we need you to upload an interior photo of the location for your installation.',
                ctaText: 'Upload Interior Image',
                type: 'image',
            },
            picExterior: {
                message: 'Before you proceed, we need you to upload an interior photo of the location for your installation.',
                ctaText: 'Upload Exterior Image',
                type: 'image',
            }
        }

        function getFormattedAlert(messages) {

            let messagesWithCta = messages.filter((message) => typeof validationCtas[message.field] !== 'undefined')
            const messageText = 'Before you proceed, we need some additional information from you.';
            let ctas = [];

            if (messagesWithCta.length) {
                ctas = messagesWithCta.map((message) => {
                    return `
                        <a href="/shop?step=${ message.step }">${validationCtas[message.field].ctaText}</a>
                    `;
                })
            }

            return `
            <p>${messageText}</p>
            <div class="alert__cta-group">${ ctas.join("\n") }</div>
            `
        }

        function validateCheckoutRules(projectModel, rules) {

            validationMessages = [];
            const invalidFields = rules.filter((checkoutRule) => {
                const fields = checkoutRule[0];
                const rule = checkoutRule[1];
                const message = checkoutRule['message'];

                const invalidRequiredField = fields.find(
                    (field) => {
                        return projectModel[field].length === 0 
                            && 
                            rule === 'required';
                    }
                );

                if (invalidRequiredField) {
                    const stepDetails = details.find((detail) => invalidRequiredField === detail.name)

                    validationMessages.push({
                        field: invalidRequiredField,
                        message: message,
                        step: stepDetails ? stepDetails.step : '',
                    })
                    return true;
                }

                return false;
            });

            return invalidFields.length ? false : true;
        }

        if (fromCheckoutValidation && !validateCheckoutRules(project, checkoutRules)) {
            window.addEventListener('load', () => {
                events.emit(actions.loadAlert, { alert: getFormattedAlert(validationMessages) });
            })
        }

        checkoutButton.addEventListener('click', (event) => {
            if (!validateCheckoutRules(project, checkoutRules)) {
                event.preventDefault();
                events.emit(actions.loadAlert, { alert: getFormattedAlert(validationMessages) });
            }
        })
    }
}
