import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class {
    constructor({
        id,
        closeHandle,
        contentHandle,
        activeClass,
        actions,
        events,
        refresh,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const content = el.querySelector(contentHandle);
        const close = el.querySelector(closeHandle);

        // Event handler functions
        function handleKeyup(e) {
            // Only car about escape key
            if (e.keyCode !== 27) return;

            events.emit(actions.closeAlert);
        }
        function handleOpenAlert() {
            el.classList.add(activeClass);
            disableBodyScroll(el);

            document.addEventListener('keyup', handleKeyup);
        }
        function handleCloseAlert() {
            el.classList.remove(activeClass);
            enableBodyScroll(el);

            document.removeEventListener('keyup', handleKeyup);
        }
        function handleLoadAlert(e) {
            const { alert } = e.detail;

            content.innerHTML = alert;
            refresh(content);
            handleOpenAlert(e);
        }
        function handleClick() {
            events.emit(actions.closeAlert);
        }

        // Add event listeners
        events.on(actions.openAlert, handleOpenAlert);
        events.on(actions.closeAlert, handleCloseAlert);
        events.on(actions.loadAlert, handleLoadAlert);
        close.addEventListener('click', handleClick);
    }
}
