import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        payFormHandle,
        confirmFormHandle,
        cardHandle,
        errorHandle,
        publicKey,
        clientSecret,
        accountId,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const payForm = el.querySelector(payFormHandle);
        const submit = payForm.querySelector('[type="submit"]');
        const confirmForm = el.querySelector(confirmFormHandle);
        const card = el.querySelector(cardHandle);
        const error = el.querySelector(errorHandle);
        const script = document.createElement('script');

        function handleStripeScriptLoad() {
            const stripe = Stripe(publicKey, { // eslint-disable-line no-undef
                stripeAccount: accountId,
            });
            const stripeElements = stripe.elements();
            const stripeCard = stripeElements.create('card');

            function handleChange(e) {
                // Disable the Pay button if there are no card details in the Element
                submit.disabled = e.empty;
                error.textContent = e.error ? e.error.message : '';
            }
            function handleSubmit(e) {
                e.preventDefault();

                submit.disabled = true;

                // Get correct name for card
                const formData = new FormData(payForm);

                stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: stripeCard,
                        billing_details: {
                            name: formData.get('name'),
                        },
                    },
                }).then(result => {
                    if (result.error) {
                        // Show error to your customer
                        error.textContent = result.error.message;
                        submit.disabled = false;

                        return;
                    }

                    // The payment succeeded - post confirmation form
                    router.post({
                        formData: new FormData(confirmForm),
                        cb: res => {
                            const { redirect = window.location.href } = JSON.parse(res);

                            events.emit(actions.completeCheckout);
                            setTimeout(() => { window.location = redirect; }, 3000);
                        },
                    });
                });
            }

            stripeCard.on('change', handleChange);
            payForm.addEventListener('submit', handleSubmit);

            // Mount card to DOM
            stripeCard.mount(card);
        }

        script.addEventListener('load', handleStripeScriptLoad);

        // Add Stripe to the global namespace
        script.src = 'https://js.stripe.com/v3/';
        document.head.appendChild(script);
    }
}
