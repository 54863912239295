export default function ({ actions, events }) {
    const footnoteLinks = document.querySelectorAll('a[href*="#footnote"]:not([data-ready="true"])');

    function handleFootnoteLink(e) {
		e.preventDefault();

		const { href } = e.currentTarget;
		const footnote = document.getElementById(href.split('#')[1]);

		events.emit(actions.loadAlert, { alert: footnote.innerHTML });
    }

    footnoteLinks.forEach(l => {
        l.setAttribute('data-ready', true);
        l.addEventListener('click', handleFootnoteLink);
    });
}
