import { scroll } from 'ui-utilities';

export default function ({ state }) {
	const anchors = document.querySelectorAll('a[href*="#"][data-smooth-scroll="true"]:not(a[href*="footnote"]):not([data-ready="true"])');

    function handleAnchor(e) {
        e.preventDefault();

        scroll.to(e.currentTarget.href.split('#')[1], state.headerHeight);
    }

    anchors.forEach(a => {
        a.setAttribute('data-ready', true);
        a.addEventListener('click', handleAnchor);
    });
}
