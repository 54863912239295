import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        loadingHandle,
        stepsContHandle,
        paymentContHandle,
        termsHandle,
        successClass,
        actions,
        events,
        refresh,
    }) {
        const el = document.getElementById(id);
        const loading = el.querySelector(loadingHandle);
        const stepsCont = el.querySelector(stepsContHandle);
        const paymentCont = el.querySelector(paymentContHandle);
        const terms = el.querySelector(termsHandle);
        const disclaimer = el.querySelector('.order-checkout__disclaimer-cont')

        function handleUpdateCheckout() {
            const { protocol, hostname } = window.location;
            const url = `${protocol}//${hostname}/shared/order-checkout`;

            function cb(res) {
                const { stepsMarkup, paymentMarkup } = JSON.parse(res);

                stepsCont.innerHTML = stepsMarkup;
                refresh(stepsCont);
                paymentCont.innerHTML = paymentMarkup;
                refresh(paymentCont);
                events.emit(actions.closeModal);
                loading.style.display = 'none';
            }

            loading.style.display = 'block';
            router.get({ url, cb });
        }
        function handleCompleteCheckout() {
            // Flash success message and redirect to order summary
            el.classList.add(successClass);
            disclaimer.classList.add('checked')
        }
        function handleTermsUpdate() {
            const termsForm = terms.closest('form');
            const formData = new FormData(termsForm);
            disclaimer.classList.add('checked')
            router.post({ formData, cb: handleUpdateCheckout });
        }

        events.on(actions.updateCheckout, handleUpdateCheckout);
        events.on(actions.completeCheckout, handleCompleteCheckout);
        terms.addEventListener('change', handleTermsUpdate);
    }
}
