// https://github.com/vimeo/player.js
import Player from '@vimeo/player';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class {
    constructor({
        id,
		playerId,
        closeHandle,
        activeClass,
        actions,
        events,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const close = el.querySelector(closeHandle);

		let player = null;

        // Event handler functions
        function handleKeyup(e) {
            // Only car about escape key
            if (e.keyCode !== 27) return;

            events.emit(actions.closeVideo);
        }
        function handleOpenVideo() {
			if (player) {
				player.play();
			}

            el.classList.add(activeClass);
            disableBodyScroll(el);

            document.addEventListener('keyup', handleKeyup);
        }
        function handleCloseVideo() {
			if (player) {
				player.pause();
			}

            el.classList.remove(activeClass);
            enableBodyScroll(el);

            document.removeEventListener('keyup', handleKeyup);
        }
        function handleLoadVideo(e) {
            const { url } = e.detail;

			player = new Player(playerId, {
				url,
				autoplay: true,
			});

            handleOpenVideo();
        }
        function handleClick() {
            events.emit(actions.closeVideo);
        }

        // Add event listeners
        events.on(actions.openVideo, handleOpenVideo);
        events.on(actions.closeVideo, handleCloseVideo);
        events.on(actions.loadVideo, handleLoadVideo);
        close.addEventListener('click', handleClick);
    }
}
