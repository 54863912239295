export default class {
	constructor({
		id,
		filterFormHandle,
		sortFormHandle,
		activeClass,
	}) {
		const el = document.getElementById(id);
		const filterForm = el.querySelector(filterFormHandle);
		const filters = filterForm.querySelectorAll('select');
		const toggle = filterForm.querySelector('button');
		const sortForm = el.querySelector(sortFormHandle);
		const sort = sortForm.querySelector('select');

		function handleFilter() {
			filterForm.submit();
		}
		function handleToggle() {
			filterForm.classList.toggle(activeClass);
		}
		function handleSort() {
			sortForm.submit();
		}

		filters.forEach(f => {
			f.addEventListener('change', handleFilter);
		});
		toggle.addEventListener('click', handleToggle);
		sort.addEventListener('change', handleSort);
	}
}
