import {
	CategoryScale,
	Chart,
	LineController,
	LineElement,
	PointElement,
	LinearScale,
    Tooltip,
} from 'chart.js';

export default class {
    constructor({
        id,
        headers,
        data,
    }) {
        const el = document.getElementById(id);
        const canvas = el.querySelector('canvas');

		Chart.register(
			CategoryScale,
			LineController,
			LineElement,
			PointElement,
			LinearScale,
            Tooltip,
		);

        new Chart(canvas, { // eslint-disable-line no-new
            type: 'line',
            data: {
                labels: headers,
                datasets: [{
                    data,
                    borderColor: 'rgba(204, 3, 3, 1)',
                    borderWidth: 3,
                }],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Required Steps',
                        },
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Percent Completion',
                        },
                    },
                },
            },
        });
    }
}
