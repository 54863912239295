export default function ({ actions, events }) {
    const vimeoLinks = document.querySelectorAll('a[href*="vimeo"]:not([data-ready="true"])');

    function handleVimeoLink(e) {
		e.preventDefault();

		const { href: url } = e.currentTarget;

		events.emit(actions.loadVideo, { url });
    }

    vimeoLinks.forEach(l => {
        l.setAttribute('data-ready', true);
        l.addEventListener('click', handleVimeoLink);
    });
}
