export default class {
  constructor({
      id,
      activeClass,
      actions,
      events,
  }) {
      const el = document.getElementById(id);
      const triggers = el.querySelectorAll('button');

      function handleTrigger({ currentTarget: trigger }) {
          triggers.forEach(t => {
              if (t.classList.toggle(activeClass, t === trigger)) {
                  events.emit(actions.setCalendarDay, { day: t.getAttribute('data-day') });
              }
          });
      }

      triggers.forEach(t => {
          t.addEventListener('click', handleTrigger);
      });
  }
}
