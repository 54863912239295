export default function () {
    const links = document.querySelectorAll('a[href*="livechat"]:not([data-ready="true"])');

    function handleClick(e) {
        e.preventDefault();

        /* eslint-disable no-undef */
        if (LiveChatWidget) {
            LiveChatWidget.call('maximize');
        }
        /* eslint-enable no-undef */
    }

    links.forEach(l => {
        l.setAttribute('data-ready', true);
        l.addEventListener('click', handleClick);
    });
}
