import { router, scroll } from 'ui-utilities';

export default class {
    constructor({
        id,
        progressHandle,
        nextContHandle,
        prevFormHandle,
        finePrintHandle,
        inProcessClass,
        showPreviousButtonClass,
        projectCompletedHidePreviousStepOnPageLoad,
        step: s0,
        actions,
        events,
        refresh,
    }) {
        const el = document.getElementById(id);
        const progress = el.querySelector(progressHandle);
        const projectSaveCont = el.querySelector('#save-form + noscript');
        const nextCont = el.querySelector(nextContHandle);
        const prevForm = el.querySelector(prevFormHandle);
        const finePrint = el.querySelector(finePrintHandle);
        let currentStep = s0;
        let totalMarkup = '';

        // eslint-disable-next-line no-console
        console.log('projectCompletedHidePreviousStepOnPageLoad', projectCompletedHidePreviousStepOnPageLoad);

        if (projectCompletedHidePreviousStepOnPageLoad) {
            document.getElementsByClassName('order-builder__prev-form')[0].style.display = 'none';
            document.querySelector('.order-builder__next-cont .build-step:last-child .btn-next').style.marginLeft = '0';
        } else {
            document.getElementsByClassName('order-builder__prev-form')[0].style.display = '';
            document.querySelector('.order-builder__next-cont .build-step:last-child .btn-next').style.marginLeft = '62.4px';
        }

        function checkStepInProgress(progressSteps, step) {
            const processKeys = Object.keys(progressSteps);
            return processKeys.some(key => progressSteps[key].progressBarSteps.includes(step));
        }

        function checkForPreliminaryStep(preliminarySteps, step) {
            return preliminarySteps.includes(step);
        }

        function smoothScrollTo(element) {
            const headerOffset = 150;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }

        function getStep({
            step,
            pushState = false,
            dir = 'right',
        }) {
            const {
                protocol,
                hostname,
            } = window.location;
            const url = `${protocol}//${hostname}/shared/order-builder`;
            const query = { step, dir };

            function cb(res) {
                const {
                    markup,
                    progressSteps,
                    preliminarySteps,
                    progress: prog,
                    product: prod,
                    projectSaveForm = null,
                    finePrint: fp,
                    stepHasResponse,
                    redirect,
                    projectCompletedHidePreviousStepAjax,
                } = JSON.parse(res);

                // eslint-disable-next-line no-console
                console.log('projectCompletedHidePreviousStepAjax', projectCompletedHidePreviousStepAjax);

                // Keep track of current step to send w/ each prev request
                currentStep = step;

                nextCont.setAttribute('data-direction', dir);

                if (redirect) {
                    window.location.href = redirect;
                    return;
                }

                if (markup.length > 1) {
                    window.isCombinedStep = true;
                    el.classList.add('combined-steps');
                } else {
                    window.isCombinedStep = false;
                    el.classList.remove('combined-steps');
                }

                totalMarkup = '';

                for (let i = 0; i < markup.length; i++) {
                    totalMarkup += markup[i];
                }

                nextCont.innerHTML = totalMarkup;
                refresh(nextCont);

                if (projectCompletedHidePreviousStepAjax) {
                    document.getElementsByClassName('order-builder__prev-form')[0].style.display = 'none';
                    document.querySelector('.order-builder__next-cont .build-step:last-child .btn-next').style.marginLeft = 0;
                } else {
                    document.getElementsByClassName('order-builder__prev-form')[0].style.display = '';
                    document.querySelector('.order-builder__next-cont .build-step:last-child .btn-next').style.marginLeft = '62.4px';
                }

                // On page load, check of the current stap has a response.
                // If so, indicate the step is completed
                if (!stepHasResponse) {
                    nextCont.classList.remove('step-completed');
                }

                const stepElements = Array.from(nextCont.querySelectorAll('[data-step-complete]'));
                const hasIncompleteStep = stepElements.find(stepElement => stepElement.dataset.stepComplete === 'false');

                if (hasIncompleteStep) {
                    nextCont.classList.remove('step-completed');
                } else {
                    nextCont.classList.add('step-completed');
                }

                if (markup.length === 1) {
                    // Scroll to the top of body whenever a single step is on screen
                    scroll.top(document.body);
                } else {
                    const scrollToActiveStep = document.getElementsByClassName(step)[0];
                    // eslint-disable-next-line no-console
                    smoothScrollTo(scrollToActiveStep);
                }

                Object.keys(progressSteps)
                    .forEach(categoryKey => {
                        const categoryElement = progress.querySelector(`.step-${categoryKey}`);

                        categoryElement.classList.remove('active');
                        categoryElement.classList.remove('pending');
                        categoryElement.classList.remove('complete');

                        categoryElement.classList.add(progressSteps[categoryKey].status);
                        if (progressSteps[categoryKey].status === 'active') {
                            const progressElement = categoryElement.querySelector('.progress');
                            progressElement.style.clipPath = `inset(0 ${100 - progressSteps[categoryKey].percentage}% 0 0)`;
                        }
                    });
                const stepInProgress = checkStepInProgress(progressSteps, currentStep);
                el.classList.toggle(inProcessClass, stepInProgress);

                const isPreliminaryStep = checkForPreliminaryStep(preliminarySteps, currentStep);
                el.classList.toggle(showPreviousButtonClass, isPreliminaryStep);

                if (projectSaveForm !== null) {
                    projectSaveCont.innerHTML = projectSaveForm;
                }

                finePrint.textContent = fp;

                document.querySelectorAll('[data-project-cta]').forEach(projectCta => {
                    if (stepInProgress) {
                        projectCta.innerHTML = projectCta.dataset.projectStarted;
                    }
                });

                if (pushState) {
                    window.history.pushState(
                        {
                            progress: prog,
                            product: prod,
                            step,
                        },
                        document.title,
                        `?step=${step}`,
                    );

                    if (window.dataLayer) {
                        window.dataLayer.push({
                            event: 'virtualPageView',
                            virtualPagePath: window.location.pathname + window.location.search,
                            virtualPageTitle: `Shop - ${step}`,
                        });
                    }
                }
            }

            console.log('query for request');
            console.log(query);

            router.get({
                url,
                query,
                cb,
            });
        }

        function handlePopState({ state }) {
            getStep(state);
        }

        function handleGetNextStep({ detail }) {
            const { nextStep: step } = detail;

            getStep({
                step,
                pushState: true,
            });
        }

        function handlePrevForm(e) {
            e.preventDefault();

            const formData = new FormData(prevForm);

            // Use current step maintained in local state
            formData.set('step', currentStep);

            function cb(res) {
                const { prevStep: step } = JSON.parse(res);

                getStep({
                    step,
                    pushState: true,
                    dir: 'left',
                });
            }

            router.post({
                formData,
                cb,
            });
        }

        window.addEventListener('popstate', handlePopState);
        events.on(actions.getNextStep, handleGetNextStep);
        prevForm.addEventListener('submit', handlePrevForm);

        // Initialize current state
        window.history.replaceState({
            ...window.history.state,
            step: s0,
        }, null);

        window.addEventListener('load', () => {
            const params = new URLSearchParams(window.location.search);
            const loadedStep = params.get('step');
            if (loadedStep) {
                // Jump to the question if it's not the first question in the list.
                const allBuildSteps = [...document.querySelectorAll('.build-step')];
                if (allBuildSteps.length > 1 && !allBuildSteps[0].classList.contains(loadedStep)) {
                    const scrollToActiveStep = document.getElementsByClassName(loadedStep)[0];

                    if (scrollToActiveStep) {
                        smoothScrollTo(scrollToActiveStep);
                    }
                }
            }
        });
    }
}
