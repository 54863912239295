// import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        // actions,
        // events,
    }) {
        const el = document.getElementById(id);
        const findAnInstaller = el.querySelector('[href*="#find-installer"]')

        const headerLocationButton = document.querySelector('.header__location')
        const headerLocationPopout = document.querySelector('.header__location-popout')
        const mobileNavigation = document.querySelector('.header__bottom-nav-mobile')

        const headerLocationPopoutInput = headerLocationPopout.querySelector('input[name=zip]')

        findAnInstaller.addEventListener('click', (event) => {
            event.preventDefault()
            event.stopPropagation()
            headerLocationButton.dispatchEvent(new Event('click'))

            requestAnimationFrame(() => {
                headerLocationPopoutInput.focus()

                if (window.getComputedStyle(mobileNavigation).getPropertyValue('display') !== 'none') {
                    window.scrollTo(0, 0)
                }
            })
        })
    }
}
