import { gsap } from "gsap";

class Accordion {

    constructor(element) {
      this.element = element;
      this.buttons = this.element.querySelectorAll('[aria-controls]')
  
      this.handleButtonClick = this.handleButtonClick.bind(this)
      this.toggleItemState = this.toggleItemState.bind(this)
    }
  
    handleButtonClick(event) {
      const button = event.target
  
      const activeButton = this.element.querySelector('.active [aria-controls]')
  
      if (button === activeButton) {
        this.toggleItemState(button)
        return;
      }
  
      if (activeButton) {
        this.toggleItemState(activeButton)
      }
  
      this.toggleItemState(button)
    }
    /* eslint-disable */
    toggleItemState(button) {
  
      const contentId = button.getAttribute('aria-controls')
      const itemWrapper = button.closest('.accordion-item')

      const contentElement = itemWrapper.querySelector(`#${contentId}`)
  
      if (itemWrapper.classList.contains('active')) {
        button.setAttribute('aria-expanded', 'false')
        itemWrapper.classList.remove('active')
        gsap.to(contentElement, {
          maxHeight: `${0}px`,
          duration: .5,
          ease: "power4.inOut",
        })
        return;
      }
  
      button.setAttribute('aria-expanded', 'true')
      itemWrapper.classList.add('active')
      const itemContent = contentElement.querySelector('.accordion-item__content-wrapper')
      const itemContentRect = itemContent.getBoundingClientRect()
  
      gsap.fromTo(contentElement,
        { maxHeight: `0px`},
        { maxHeight: `${itemContentRect.height}px`, duration: .5, ease: "power4.inOut", }
      )
    }
    /* eslint-enable */

    init() {
  
      this.buttons.forEach((button) => {
        button.addEventListener('click', this.handleButtonClick)
      })
  
    }
}

export default Accordion