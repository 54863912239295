export default function () {
    const tabBlocks = document.querySelectorAll('.js-tabs');

    function setTabState(tab, active) {
        const tabContainer = tab.closest('.js-tabs')
        const tabPanelId = tab.getAttribute('aria-controls')
        const tabPanel = tabContainer.querySelector(`#${tabPanelId}`)

        if (!tabPanel) {
            console.warn('No tab panel available for tab', tab)
            return;
        }

        if (active) {
            tab.classList.add('active')
            tab.setAttribute('aria-selected', 'true')
    
            tabPanel.classList.add('active')
            return
        }

        tab.classList.remove('active')
        tab.setAttribute('aria-selected', 'false')

        tabPanel.classList.remove('active')
    }

    function handleTabClick(tab) {

        const selectedTab = tab.parentNode.querySelector('.active')

        // deactivate the current tab selection
        setTabState(selectedTab, false)

        // activate the new tab
        setTabState(tab, true)
    }

    tabBlocks.forEach((tabBlock) => {
        const tabs = tabBlock.querySelectorAll('[role="tab"]')
        tabs.forEach((tab) => {
            tab.addEventListener('click', (event) => {
                handleTabClick(event.target)
            })
        })
    })
}
