import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class {
    constructor({
        id,
        closeHandle,
        contentHandle,
        activeClass,
        actions,
        events,
        refresh,
    }) {
        // Elements and class variables
        let el = document.getElementById(id);
        const content = el.querySelector(contentHandle);
        const close = el.querySelector(closeHandle);
        // const urlParams = new URL(window.location.toLocaleString()).searchParams;
        // const modalOpen = urlParams.get('modal');
        // console.log(modalOpen)
        // // Local variable to allow calling functions to name modal instance
        //

        // if(modalOpen){
        //   let el = document.getElementById(modalOpen);
        //   events.on(actions.loadModal, handleLoadModal);
        // }
        let name = '';
        // Event handler functions
        function handleKeyup(e) {
            // Only car about escape key
            if (e.keyCode !== 27) return;

            events.emit(actions.closeModal, { name });
        }
        function handleOpenModal(e) {
            const {
                name: n = '',
                bg = 'dk',
                size = 'sm',
            } = e.detail;

            // Store name of model instance passed by caller to emit when closing
            name = n;

            el.setAttribute('data-bg', bg);
            el.setAttribute('data-size', size);
            el.classList.add(activeClass);
            disableBodyScroll(el);

            document.addEventListener('keyup', handleKeyup);
        }
        function handleCloseModal() {
            el.classList.remove(activeClass);
            enableBodyScroll(el);

            document.removeEventListener('keyup', handleKeyup);
        }
        function handleLoadModal(e) {
            const { markup } = e.detail;

            content.innerHTML = markup;
            refresh(content);
            handleOpenModal(e);
        }
        function handleClick() {
            events.emit(actions.closeModal, { name });
        }

        // Add event listeners
        events.on(actions.openModal, handleOpenModal);
        events.on(actions.closeModal, handleCloseModal);
        events.on(actions.loadModal, handleLoadModal);
        close.addEventListener('click', handleClick);
    }
}
