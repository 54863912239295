import { router } from 'ui-utilities';

export default class {
  constructor({
    id,
    loadingHandle,
    ordersHandle,
    calendarHandle,
    installerId,
    month,
    year,
    ordersByDay,
    actions,
    events,
    refresh,
  }) {
    const el = document.getElementById(id);
    const loading = el.querySelector(loadingHandle);
    const orders = el.querySelector(ordersHandle);
    let orderButtons = document.querySelector('.orders-calendar__filter');
    let orderSelects = orderButtons.querySelectorAll('button')
    let tableHeader = document.querySelector('.table-headers')
    let orderCards = document.querySelectorAll('.order-card')
    let details = document.querySelectorAll('.details')
    // const calendar = el.querySelector(calendarHandle);
    // const [prev, next] = el.querySelectorAll('nav > form');

    // Keep track of month, year and corresponding orders by day
    month = parseInt(month, 10);
    year = parseInt(year, 10);

    function populateOrdersByDay(day = null) {
      const markup = day
        ? ordersByDay[`_${day}`]
        : Object.values(ordersByDay)[0] || '<p>No orders for this month.</p>';

      orders.innerHTML = markup;
      refresh(orders);
    }
    function getCalendar(mo, yr) {

      function cb(res) {
        const { ordersByDay: o, calendar: c } = JSON.parse(res);

        // Update local state variables
        ordersByDay = o;
        populateOrdersByDay();
        loading.style.display = 'none';
      }

      loading.style.display = 'block';
      router.get({ url, query, cb });
    }

    function handleSetCalendarDay(e) {
      const { day } = e.detail;

      populateOrdersByDay(day);
    }


    // function handlePrev(e) {
    //     e.preventDefault();

    //     const lastMonth = month - 1;

    //     year = lastMonth < 1 ? year - 1 : year;
    //     month = lastMonth < 1 ? 12 : lastMonth;

    //     getCalendar(month, year);
    // }
    // function handleNext(e) {
    //     e.preventDefault();

    //     const nextMonth = month + 1;

    //     year = nextMonth > 12 ? year + 1 : year;
    //     month = nextMonth > 12 ? 1 : nextMonth;

    //     getCalendar(month, year);
    // }]


    // events.on(actions.setCalendarDay, handleSetCalendarDay);


    // populateOrdersByDay();

    const setActive = (el) => {
      [...el.parentElement.children].forEach((sib) =>
        sib.classList.remove("is-selected")
      );
      el.classList.add("is-selected");
    };

    orderSelects.forEach(select => {
      select.addEventListener('click', event => {
        setActive(select);
        let category = select.dataset.section;
        let allHeaders = tableHeader.querySelectorAll('span')
        tableHeader.classList.add('is-selected')
        allHeaders.forEach(allHeader => {
          allHeader.classList.remove('is-selected')
        })
        orderCards.forEach(orderCard => {
          orderCard.classList.remove('is-selected')
          tableHeader.classList.add('is-selected')
          if (orderCard.classList.contains(category)) {
            orderCard.classList.add('is-selected')
          }
        })

        details.forEach(detail => {
          detail.classList.remove('is-selected')
          if (detail.classList.contains(category)) {
            detail.classList.add('is-selected')
          }
        })

        let header = tableHeader.querySelector('.' + category);
        header.classList.add('is-selected')

      })
    })

  }
}
