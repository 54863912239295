import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        deleteHandle,
    }) {
        const el = document.getElementById(id);
        const deleteForm = el.querySelector(deleteHandle);

        function handleDelete(e) {
            e.preventDefault();

            if (!window.confirm('Are you sure you want to delete this project?')) { // eslint-disable-line no-alert
                return;
            }

            const formData = new FormData(deleteForm);

            function cb(res) {
                const { success, message } = JSON.parse(res);

                if (!success) {
                    window.alert(message); // eslint-disable-line no-alert

                    return;
                }

                el.innerHTML = `<p>${message}</p>`;
            }

            router.post({ formData, cb });
        }

        if (deleteForm) {
            deleteForm.addEventListener('submit', handleDelete);
        }
    }
}
