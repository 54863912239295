import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        loadingHandle,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');
        const loading = el.querySelector(loadingHandle);

        // Event handler functions
        function handleSubmit(e) {
            e.preventDefault();

            const formData = new FormData(form);

            function cb(res) {
                loading.style.display = 'none';

                const {
                    success,
                    errors,
                    application = null,
                } = JSON.parse(res);

                if (!success) {
                    // Merge formData w/ errors from controller to update error
                    // text for all inputs from form, sent to controller.
                    const names = [
                        ...Array.from(formData.keys()),
                        ...Object.keys(errors),
                    ];

                    names.forEach(name => {
                        const error = errors[name] || '';

                        events.emit(actions.setInputError, { name, error });
                    });

                    return;
                }

                // Remove form and launch application
                if (application && window.confirm('You will be taken offsite to complete your financing application. When you are done you will be brought back to complete checkout.')) { // eslint-disable-line no-alert
                    form.removeEventListener('submit', handleSubmit);
                    form.remove();
                    window.location = application.redirectUrl;
                }
            }

            loading.style.display = 'block';
            router.post({ formData, cb });
        }

        // Attach events
        form.addEventListener('submit', handleSubmit);
    }
}
