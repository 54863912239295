import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/mobilefriendly';

export default class {
    constructor({
        id,
        activeClass,
        conditional,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const input = el.querySelector('input, select, textarea');
        const error = el.querySelector('p');
        const form = el.closest('form');
        const targets = conditional
            ? form.querySelectorAll(`[name="${conditional.name}"]`)
            : [];
        const submitConditions = el.querySelectorAll(['[data-submit-condition]']);

        function handleSetInputError(e) {
            const { name, error: errorText } = e.detail;

            if (input.name !== name) {
                return;
            }

            error.textContent = errorText;
        }
        function handleFocus() {
            el.classList.add(activeClass);
        }
        function handleBlur() {
            el.classList.toggle(activeClass, input.value || input.tagName === 'SELECT');
        }
        function handleTargetChange(e) {
            const { value } = e.currentTarget;

            el.style.display = conditional.value.includes(value) ? 'block' : 'none';
        }
        function handleSubmitCondition({ currentTarget }) {
            const formName = currentTarget.getAttribute('data-submit-condition');

            if (Array.from(submitConditions).every(c => c.checked)) {
                events.emit(actions.enableSubmit, { formName });
            } else {
                events.emit(actions.disableSubmit, { formName });
            }
        }

        events.on(actions.setInputError, handleSetInputError);
        input.addEventListener('focus', handleFocus);
        input.addEventListener('blur', handleBlur);
        targets.forEach(target => {
            target.addEventListener('change', handleTargetChange);
        });
        submitConditions.forEach(c => {
            c.addEventListener('change', handleSubmitCondition);
        });

        // Initialize conditional formatting
        if (conditional) {
            const formData = new FormData(form);

            el.style.display = conditional.value.includes(formData.get(conditional.name)) ? 'block' : 'none';
        }

        // Initialize date range litepicker
        if (input.getAttribute('data-litepicker')) {
            new Litepicker({ // eslint-disable-line no-new
                element: input,
                plugins: ['mobilefriendly'],
                splitView: false,
                resetButton: true,
                singleMode: false,
                numberOfColumns: 2,
                numberOfMonths: 2,
                dropdowns: {
                    minYear: 2021, // year that site was built - no prior orders
                    maxYear: null, // defaults to current year
                    months: true,
                    years: true,
                },
                setup: picker => {
                    picker.on('show', () => {
                        let date = picker.getDate();

                        if (date === null) {
                            date = new Date();
                            date.setMonth(date.getMonth() - 1);
                            picker.gotoDate(date);
                        }
                    });
                    // enforce active state when handleBlur() would have removed it
                    picker.on('before:click', () => {
                        el.classList.add(activeClass);
                    });
                    picker.on('hide', () => {
                        el.classList.toggle(activeClass, picker.getDate() !== null);
                    });
                    picker.on('selected', () => {
                        el.closest('form').submit();
                    });
                },
            });
        }
    }
}
