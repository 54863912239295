import Swiper, { Scrollbar } from 'swiper';

// configure Swiper to use modules
Swiper.use([Scrollbar]);

export default class {
    constructor({
        id,
        counterId,
        dragHandle,
        draggingClass,
    }) {
        const el = document.getElementById(id);
        const counter = counterId ? document.getElementById(counterId) : null;
        const drag = el.querySelector(dragHandle);

        const swiper = new Swiper('.swiper-container', {
            initialSlide: 1,
            centeredSlides: true,
            slidesPerView: 'auto',
        });

        function handleMouseEnter() {
            drag.style.opacity = 1;
        }
        function handleMouseLeave() {
            drag.style.opacity = 0;
        }
        function handleMouseMove({ clientX: x, clientY: y }) {
            const { top, left } = el.getBoundingClientRect();

            drag.style.transform = `translate(calc(${x - left}px - 50%), calc(${y - top}px - 50%))`;
        }
        function handleSlideChange({ realIndex, slides }) {
            if (counter) {
                counter.textContent = `0${realIndex + 1} // 0${slides.length}`;
            }
        }

        function handleTouchStart() {
            el.classList.add(draggingClass);
        }
        function handleTouchEnd() {
            el.classList.remove(draggingClass);
        }
        function handleTouchMove(_, { clientX: x, clientY: y }) {
            const { top, left } = el.getBoundingClientRect();

            drag.style.transform = `translate(calc(${x - left}px - 50%), calc(${y - top}px - 50%))`;
        }
        function handleImagesReady(s) {
            s.slideTo(1);
        }

        el.addEventListener('mouseenter', handleMouseEnter);
        el.addEventListener('mouseleave', handleMouseLeave);
        el.addEventListener('mousemove', handleMouseMove, { passive: true });
        swiper.on('slideChange', handleSlideChange);
        swiper.on('touchStart', handleTouchStart);
        swiper.on('touchEnd', handleTouchEnd);
        swiper.on('touchMove', handleTouchMove);
        swiper.on('imagesReady', handleImagesReady);

        // Initialize counter
        if (counter) {
            counter.textContent = `0${swiper.realIndex + 1} // 0${swiper.slides.length}`;
        }
    }
}
