import pop from 'compop';
import './polyfills';

// Components
import AdminOrdersListing from './components/admin-orders-listing';
import Alert from './components/alert';
import BackgroundVideo from './components/background-video';
import BuildStep from './components/build-step';
import Calendar from './components/calendar';
import CalendarPicker from './components/calendar-picker';
// import CalendlyPicker from './components/calendly-picker';
import Carousel from './components/carousel';
import DetailTrigger from './components/detail-trigger';
import FinancingForm from './components/financing-form';
import Header from './components/header';
import Input from './components/input';
import ImageInput from './components/image-input';
import InstallersList from './components/installers-list';
import InstallersHero from './components/installers-hero';
import Intro from './components/intro';
import ListInput from './components/list-input';
import Modal from './components/modal';
import ModalForm from './components/modal-form';
import OrderBuilder from './components/order-builder';
import OrderCheckout from './components/order-checkout';
import OrdersCalendar from './components/orders-calendar';
import ProjectDetail from './components/project-detail';
import ProjectCard from './components/project-card';
import StatsChart from './components/stats-chart';
import StripeCard from './components/stripe-card';
import VideoModal from './components/video-modal';

// Utility helpers
import anchorLinks from './utilities/anchor-links';
import footnoteLinks from './utilities/footnote-links';
import liveChatLinks from './utilities/live-chat-links';
import modalTriggers from './utilities/modal-triggers';
import vimeoLinks from './utilities/vimeo-links';
import tabs from './components/tabs';
import stickyElements from './utilities/sticky-events';
import accordions from './utilities/accordions';

const SITE_HANDLE = 'gsi';

// Define map of component handles to component classes
/* eslint-disable quote-props */
const classMap = {
    'alert': Alert,
	  'admin-orders-listing': AdminOrdersListing,
	  'background-video': BackgroundVideo,
    'build-step': BuildStep,
    'calendar': Calendar,
    'calendar-picker': CalendarPicker,
    'carousel': Carousel,
    'detail-trigger': DetailTrigger,
    'financing-form': FinancingForm,
    'header': Header,
    'input': Input,
    'image-input': ImageInput,
    'installers-list': InstallersList,
    'installers-hero': InstallersHero,
    'intro': Intro,
    'list-input': ListInput,
    'modal': Modal,
    'modal-form': ModalForm,
    'order-builder': OrderBuilder,
    'order-checkout': OrderCheckout,
    'orders-calendar': OrdersCalendar,
    'project-detail': ProjectDetail,
    'project-card': ProjectCard,
    'stats-chart': StatsChart,
    'stripe-card': StripeCard,
	'video-modal': VideoModal,
};
/* eslint-enable quote-props */

// Define all actions/commands that components pub/sub
const actions = {
    loadAlert: 'LOAD_ALERT',
    loadModal: 'LOAD_MODAL',
    openModal: 'OPEN_MODAL',
    closeModal: 'CLOSE_MODAL',
    loadVideo: 'LOAD_VIDEO',
    lockLocation: 'LOCK_LOCATION',
    lockScroll: 'LOCK_SCROLL',
    unlockScroll: 'UNLOCK_SCROLL',
    getNextStep: 'GET_NEXT_STEP',
    setActiveLocation: 'SET_ACTIVE_LOCATION',
    enableSubmit: 'ENABLE_SUBMIT',
    disableSubmit: 'DISABLE_SUBMIT',
    setInputValue: 'SET_INPUT_VALUE',
    setInputError: 'SET_INPUT_ERROR',
    setCalendarDay: 'SET_CALENDAR_DAY',
    updateCheckout: 'UPDATE_CHECKOUT',
    confirmCheckout: 'CONFORM_CHECKOUT',
    completeCheckout: 'COMPLETE_CHECKOUT',
};

// Event handler functions
function handleDOMConentLoaded() {
    const scaffold = window[SITE_HANDLE];

    // Functionality for after components initialize
    function cb({ state, events }) {
        anchorLinks({ state });
        footnoteLinks({ actions, events });
        liveChatLinks();
        modalTriggers({ actions, events });
        vimeoLinks({ actions, events });
        tabs({ actions, events })
        stickyElements()
        accordions()
    }

    // Call component constructors
    pop({ scaffold, classMap, actions, cb });
}

function handlePostMessage(e) {
    // Check sender origin to be trusted
    // This is specific to the Unbounce "User Support CTAs" widget
    if (e.origin !== "https://657cc69cb1f440fea18e120c1c16c6d9.pages.ubembed.com") return;

    /* eslint-disable no-undef */
    if (e.data.message === 'openLiveChat' && LiveChatWidget) {
        LiveChatWidget.call('maximize');
    }
    /* eslint-enable no-undef */
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
window.addEventListener('message', handlePostMessage, false);
