export default function () {
    const stickyElements = [...document.querySelectorAll('.js-sticky')]

    var intersectionObserver = new IntersectionObserver(entries => {

        const entry = entries[0]

        let targetSelector = `[data-sticky-start-sentinel="${ entry.target.id }"]`
        let targetElement = document.querySelector(targetSelector)
        if (!targetElement) {
            return;
        }

        if (entry.intersectionRatio > 0) {
            targetElement.classList.remove('is-sticky')
        } else if (entry.intersectionRatio === 0) {
            targetElement.classList.add('is-sticky')
        }
      });
      

    stickyElements.forEach((stickyElement) => {
        if (stickyElement.dataset.stickyStartSentinel) {
            const sentinelId = stickyElement.dataset.stickyStartSentinel
            const sentinelElement = document.getElementById(sentinelId)
            intersectionObserver.observe(sentinelElement);
        }
    })
}