import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
		formName,
        reload = false,
		confirmMessage = '',
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');
		const submit = form.querySelector('[type="submit"]');

		function handleEnableSubmit({ detail }) {
			if (formName === detail.formName) {
				submit.removeAttribute('disabled');
			}
		}
		function handleDisableSubmit({ detail }) {
			if (formName === detail.formName) {
				submit.setAttribute('disabled', true);
			}
		}
        function handleSubmit(e) {
            e.preventDefault();

			if (confirmMessage && !window.confirm(confirmMessage)) { // eslint-disable-line no-alert
				return;
			}

            const formData = new FormData(form);

            function cb(res) {
                const {
					success,
					errors,
					message = ''
				} = JSON.parse(res);

                if (!success) {
                    // Merge formData w/ errors from controller to update error
                    // text for all inputs from form, sent to controller.
                    const names = [
                        ...Array.from(formData.keys()),
                        ...Object.keys(errors),
                    ];

                    names.forEach(name => {
                        const error = errors[name] || '';

                        events.emit(actions.setInputError, { name, error });
                    });

                    return;
                }

                form.removeEventListener('submit', handleSubmit);
                form.remove();
                el.insertAdjacentHTML('beforeend', `<p><strong>${message}<strong></p>`);

                if (reload) {
                    window.location.reload();
                }
            }

			router.post({ formData, cb });
        }

        events.on(actions.enableSubmit, handleEnableSubmit);
        events.on(actions.disableSubmit, handleDisableSubmit);
        form.addEventListener('submit', handleSubmit);
    }
}
