export default class {
    constructor({
        id,
        backgroundImagesContHandle,
        state,
    }) {
        const el = document.getElementById(id);
        const backgroundImagesCont = el.querySelector(backgroundImagesContHandle);
        const { headerHeight } = state;

        function handleScroll() {
            const { top, bottom } = el.getBoundingClientRect();

            if (top > window.innerHeight || bottom < 0) {
                return;
            }

            backgroundImagesCont.style.transform = `translateY(${(top - headerHeight) * 0.1}px)`;
        }

        window.addEventListener('scroll', handleScroll);
    }
}
