import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
        url,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const trigger = el.querySelector('button');

        function handleTrigger() {
            function cb(markup) {
                events.emit(actions.loadModal, { markup });
            }

            router.get({ url, cb });
        }

        trigger.addEventListener('click', handleTrigger);
    }
}
