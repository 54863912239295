export default function ({ actions, events }) {
    let modalTriggers = document.querySelectorAll('input[type="checkbox"][data-modal-trigger]:not([data-ready="true"])');
    const urlParams = new URL(window.location.toLocaleString()).searchParams;
    const modalOpen = urlParams.get('modal');
    console.log(modalOpen)
    // Local variable to allow calling functions to name modal instance
    if(modalOpen){
      autoOpen = document.getElementById(modalOpen)
    }

    function handleModalTrigger({ currentTarget: trigger }) {
        const markup = trigger.nextElementSibling.textContent
                       || trigger.nextElementSibling.innerHTML;
        const name = trigger.getAttribute('data-modal-name');
        const size = trigger.getAttribute('data-modal-size');
        // Set input checked to false to load markup in modal instead of container
        trigger.checked = false;
        // Load initial markup inside of modal for enhancement
        events.emit(actions.loadModal, { markup, name, size });
    }

    modalTriggers.forEach(mt => {
        mt.setAttribute('data-ready', true);
        mt.addEventListener('change', handleModalTrigger);
    });

    if(modalOpen){
      window.addEventListener('DOMContentLoaded', function() {
        handleModalTrigger({ currentTarget: autoOpen })
      })
    }
}
